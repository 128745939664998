.other {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  color: #777;
}

.languages {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.head{
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}

.head > h2 {
  margin-right: 10px;
}

.languages__individual > h2 {
  font-size: 25px;
  padding-bottom: 10px;
}

.languages__individual > h3 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
}

.key__skills {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.key__individual > h2 {
  font-size: 25px;
  padding-bottom: 10px;
}

.key__individual > h3 {
  font-size: 18px;
  padding-bottom: 5px;
}

.other__skills {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.other__individual > h2 {
  font-size: 25px;
  padding-bottom: 10px;
}

.other__individual > h3 {
  font-size: 18px;
  padding-bottom: 5px;
}

@media (max-width: 760px) {
  .key__skills {
    margin-left: 10px;
  }
}
