.phonevideocard {
  display: flex;
  text-align: center;
  justify-content: left;
  border-bottom: 1px solid #e5e5e5;
  color: #777;
  min-width:35vw;
  margin-bottom: 30px;
}


.phonevideo__wrapper {
  width: 200px;
  height: 350px;
  border-radius: 11px;
  overflow: hidden;
  margin-bottom: 30px;
}
.phonevideocard__content {
  text-align: left;
}

.phonevideocard__content > h3 {
  margin: 10px;
  text-align: left;
  color: #333;

}

.fold{
  display: flex;
  padding: 10px;
}

.widgets1 {
  color: #777;
}


.widgets2 {
  color: #777;
}

.widgets--selected1 {
  color: #0058f7;
  cursor: pointer;
}

.widgets--selected2 {
  color: #19b028;
  cursor: pointer;
}


@media (max-width: 860px) {
  .phonevideo__wrapper {
    width: 80px;
    height: 150px;
  }

  .phonevideocard__content > h3 {
    font-size: 15px;
    margin: 5px;
  }


}
