.resume__container {
  margin-top: 20px;
  color: #777;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;

}

.resume__container--selected {
  color: #0058f7;
}

.resume__container:hover {
  cursor: pointer;
  color: #333;
}

.resume__container > h1 {
  font-size: 20px;
}
