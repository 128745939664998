.resume {
  display: flex;
  flex-direction: column;
  margin-left: 250px;
  margin-right: 250px;
  color: #333;
  padding: 70px;
  outline: white;
  margin-bottom: 50px;
  margin-top: 80px;

}

.resume > h1 {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}


.container {
  outline:  .5px solid white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  justify-content: center;

}

.content__divider {
  display: flex;
  justify-content: center;
  width: 100%;
}

.resume__info {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-self: center;
  width: 100%;
}

@media (max-width: 1080px) {
  .resume {
    margin: 70px;
  }
}

@media (max-width: 760px) {
  .resume {
    margin: 0px;
    padding: 10px;
    margin-bottom: 50px;
  }
}
