.videocard {
  display: flex;
  text-align: center;
  justify-content: left;
  color: #777;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
  min-width: 35vw;
}


.video__wrapper {
  width: 200px;
  height: 100px;
  border-radius: 11px;
  overflow: hidden;
  margin-bottom: 30px;
}

.videocard__content {
  text-align: left;

}

.videocard__content > h3 {
  margin: 10px;
  color: #333;
}

.fold{
  display: flex;
  padding: 10px;
}

.widgets1 {
  color: #777;
}


.widgets2 {
  color: #777;
}

.widgets--selected1 {
  color: #0058f7;
  cursor: pointer;
}

.widgets--selected2 {
  color: #19b028;
  cursor: pointer;
}

@media (max-width: 860px) {
  .video__wrapper {
    width: 110px;
    height: 70px;
  }

  .videocard__content > h3 {
    font-size: 15px;
    margin: 5px;
  }

  .fold {
    font-size:15px;
  }


}
