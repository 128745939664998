.code {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  color: #777;
}

.language {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.language__font > h2 {
  font-size: 25px;
  padding-bottom: 10px;
}

.language__font > h3 {
  font-size: 18px;
  padding-bottom: 5px;
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.level {
  color: #0058f7
}

.tools {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.tools__font > h2 {
  font-size: 25px;
  padding-bottom: 10px;
  padding-top: 5px;
}

.tools__font > h3 {
  font-size: 18px;
  padding-bottom: 5px;
}

@media (max-width: 960px) {
  .head {
    flex-direction: column;
  }
}

