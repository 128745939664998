.portfolio {
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  width: 40%;
  background-color: hsla(0,0%,76.9%,.14901960784313725);
  margin-top: 160px;
  margin-bottom: 80px;
  height: 80vh;
  color: black;
  padding: 70px 70px 0px 70px;
  align-self: center;
  box-shadow: 0px -20px 10px -5px  #ecebeb inset;
}

.portfolio > h1 {
  font-size: 40px;
  text-align: center;
}

.portfolio__button {
  display: flex;
  justify-content: center;
}

.portfolio__info{
  display: flex;
  background-color:#fff;
  margin-top:10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin-bottom: 0px;
  height: 100%;
  overflow: scroll;
  box-shadow: 0px -20px 10px -5px  #ecebeb inset;
  z-index: 1;
}

@media (max-width: 760px) {
  .portfolio {
    margin: 0px;
    margin-bottom: 50px;
    width: 60%;
  }
}
