.click__button {
  color: #777;
  border-radius: 5px;
  padding: 10px;
}

.click__button > h2 {
  font-weight: 700;
  font-size: 20px;

}

.click__button:hover {
  color: #333;
  cursor: pointer;
}

.click__button--selected {
  color: #0058f7;
}
