.home {
  font-size:2.5rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 50vh;
  margin-bottom: 50vh;
  color: #333;
}

.home > h1 {
  font-size: 20px;
  color: #777;
}

.text-span {
  color: #0058f7;
  font-weight: "bolder";
}

