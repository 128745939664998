#flyoutMenu {
  display: flex;
  flex-direction: column;
  width: 150px;
  font-size: 10px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  position: fixed;
  align-items: center;
  margin-top: 30px;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  overflow: scroll;
  z-index: 1000;
  justify-content: center;
  color: #333;

}

.home__symbol {
  font-size: 30px;
  font-weight: 700;
  margin-top: -200px;
  margin-bottom: 50px;
  color: #333;
}

#flyoutMenu.hide {
  transition: transform 0.3s;
  transform: translate(-100%);
}

#flyoutMenu.show {
  transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}

#flyoutMenu > a {
  margin-bottom: 30px;
}

.linkk > h1 {
  color: #333;
}

.linkk > h1:hover {
  color: #0058f7;
}

@media (max-width: 760px) {
  #flyoutMenu.show {
    transition: transform 0s;
    transform: translate(-100%);
  }
}
