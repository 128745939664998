.software {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  color: #777;
}

.software__select {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.head {
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}

.level {
  color: #0058f7;
}

.software__individual > h2 {
  font-size: 25px;
  padding-bottom: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.software__individual > h3 {
  font-size: 18px;
  padding-bottom: 5px;
}
