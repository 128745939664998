.card {
  display: flex;
  text-align: center;
  justify-content: left;
  border-bottom: 1px solid #e5e5e5;
  color: #777;
  margin-bottom: 30px;
  min-width:35vw;
}


.card__img > img {
  object-fit: fill;
  width: 200px;
  height: 100px;
  border-radius: 11px;
  margin-bottom: 30px;
}

.fold{
  display: flex;
  padding: 10px;
}

.widgets1 {
  color: #777;
}


.widgets2 {
  color: #777;
}

.widgets--selected1 {
  color: #0058f7;
  cursor: pointer;
}

.widgets--selected2 {
  color: #19b028;
  cursor: pointer;
}

.card__content {
  text-align: left;
}

.card__content > h3 {
  margin: 10px;
  text-align: left;
  color:#333;
}

@media (max-width: 960px){
  .card__img > img {
    width: 170px;
    height: 100px;
  }

  .card__content > h3 {
    font-size: 15px;
    margin: 5px;
  }

}

@media (max-width: 860px) {
  .card__img > img {
    width: 110px;
    height: 70px;
  }

  .card__content > h3 {
    font-size: 15px;
    margin: 5px;
  }
}

