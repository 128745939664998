.slidecard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #777;
  min-width:35vw;
}

.slidecard__wrapper {
  width: 500px;
  height: 300px;
  object-fit: fill;
  align-self: center;
}

.slidecard__content {
  text-align: center;
}

.slidecard__content > h3 {
  margin: 20px;
  color: #333;
}

@media (max-width: 860px) {
  .slidecard__wrapper {
    width: 230px;
    height: 200px;
  }

  .slidecard__content > h3{
    font-size:15px;
  }
}

@media (max-width: 960px){
  .slidecard__wrapper {
    width: 290px;
    height: 250px;
  }

  .slidecard__content > h3{
    font-size:15px;
  }

}
