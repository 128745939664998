.contact {
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  background-color: hsla(0,0%,100%,.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 60px;
  margin-left: 150px;
  margin-right: 150px;
  color: #333;
  padding: 70px;
  text-align: center;
}

.contact > h1 {
  font-size: 40px;
  text-align: center;
}

.contact > h3 {
  font-size: 20px;
  color:#777;
}

.contact__details {
  width: 50vh;
  margin-top: 10px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-around;
  color: white;
}

.phone {
  display: flex;
  align-items: center;
  color: white;
}

.email {
  display: flex;
  align-items: center;
}

.phone > h5 {
  margin-left: 10px;
  color: white;
}

.email > a {
  margin-left: 10px;
}

@media (max-width: 760px) {
  .contact {
    margin: 0px;
  }

  .contact__details {
    display: flex;
    flex-direction: column;
  }
}
