.skill__container {
  margin-top: 20px;
  color: #777;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;
}

.skill__container > h1 {
  font-size: 20px;
}

.skill__container--selected {
  color:#0058f7;
}

.skill__container:hover {
  color: #333;
  cursor: pointer;
}
