.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  width: 100%;
  padding: 30px 20px;
  color: #333;
  justify-content: center;
  justify-self: center;
}

.logo__container{
  flex: 1;
}

.logo {
  flex: 1;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color:#333;
}

.logo:hover {
  color: #0058f7;
}

.header__center {
  flex: 2;
  width: 100%;
  align-items: center;
  text-align: center;
  font-weight: 700;
  justify-content: center;
  color: #333;
}

.header__center > a {
  margin-right: 20px;
  font-size: 15px;
  color: black;
}

.header__left {
  flex: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.header__left > a:hover {
  font-size: 13px;
  color: #0058f7;
}

a:link,
a:visited {
  color: #333;
  text-decoration: none;
}
a:hover {
  color: #0058f7;
  text-decoration: none;
}

.squeeze {
  display: none;
}

.dropdown {
  display: flex;
  flex-direction: column;
  background-color: hsla(0,0%,100%,.1);
  text-align: center;
  margin-left: 20px;
  padding: 50px;
  font-size: small;
}

.dropdown > a {
  font-size: 10;
  margin-bottom: 10px;
}

@media (max-width: 1080px) {
  .header {
    display: none;
  }

  .squeeze {
    display: revert;
    color: #D7D5D5;
  }
}
