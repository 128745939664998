.single__button {
  outline: 1px solid;
  color: #777;
  border-radius: 5px;
  padding: 5px;
  margin: 15px;
}

.single__button > h5 {
  font-size: 15px;
}

.single__button:hover {
  color:#333;
  cursor: pointer;
}

.single__button--selected {
  color: #0058f7;
}
