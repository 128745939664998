* {
  margin: 0;
}

.app {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body {
  background:#fff;
  font-family:'Courier New', Courier, monospace;
}
