.email__send {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
  width: 50vh;
  align-self: center;
}

.email__info {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: left;
  font-family:'Courier New', Courier, monospace;
  font-size: 15px;

}

.email__info2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  font-family:'Courier New', Courier, monospace;
}

.email__info3 {
  display: flex;
  width: 100%;
}

.email__info3 > .subjectBox {
  flex: 1;
  resize: none;
  border-radius: 10px;
  height: 40px;
  font-size:15px;
  text-indent: 10px;
  margin-top: 10px;
  text-align: left;
}

.email__info > .inputBox {
  height: 40px;
  flex: 0.3;
  margin-right: 5px;
  font-size: 15px;
  text-indent: 10px;
  border-radius: 10px;
  text-align: left;
  resize: none;
}

input {
  outline: none;
  border: 1px solid #777;
  font-family:'Courier New', Courier, monospace;
}

.email__info > .inputBox1 {
  height: 40px;
  flex: 0.7;
  font-size: 15px;
  text-indent: 10px;
  border-radius: 10px;
  resize: none;
  text-align:left;
}

.email__info2 > .inputBox2 {
  margin-top: 10px;
  flex: 1;
  font-size: 15px;
  text-indent: 10px;
  border-radius:10px;
  resize: none;
  text-align: left;
  font-weight: 100;
}


textarea:focus {
  outline-width: 0;
}

.email__info2 > .button {
  background-color: #0058f7;
  text-transform: inherit;
  font-weight: bold;
  border-radius: 10px;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 15px;
  margin-top: 20px;
  font-size: 20px;
  font-family:'Courier New', Courier, monospace;
}

.email__info2 > .button:hover {
  background-color: white;
  color:#0058f7;
  outline: 1px solid #0058f7;
}

@media (max-width: 700px) {
  .email__info2 {
    width: 80%;
  }

  .email__info {
    width: 80%;
    flex-direction: column;
    height: 70px;
  }

  .email__info > .inputBox {
    margin-bottom: 5px;
    font-size: 15px;
    flex: 1;
    width: 100%;
  }

  .email__info > .inputBox1 {
    font-size: 15px;
    flex: 1;
    width: 100%;
  }

  .email__info3 {
    width: 80%;
  }

  .email__info3 > .subjectBox {
    margin-top: 10px;
    width: 100%;
    flex: 1;
  }

  .email__info2 > .inputBox2 {
    margin-top: 10px;
    width: 100%;
    flex: 1;
  }

  .email__info2 > .button {
    width: 100%;
  }
}
