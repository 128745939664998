.skills {
  display: flex;
  flex-direction: column;
  margin-left: 250px;
  margin-right: 250px;
  color: #333;
  padding: 70px;
  margin-bottom:50px;
  margin-top: 110px;
}

.skills > h1 {
  font-size: 40px;
  text-align: center;
}

.skills_container {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  outline: 0.5px solid white;
  width: 100%;
  align-items: stretch;
  justify-content: center;
}

.content__divider {
  display: flex;
  justify-content: center;
  width: 100%;
}

.skill__select {
  align-self: center;
  width: 100%;
}

@media (max-width: 960px) {
  .skills {
    margin: 80px;
  }
}

@media (max-width: 760px) {
  .skills {
    margin: 0px;
    padding: 10px;
    margin-bottom: 50px;
  }
}
