.about {
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  margin-right: 150px;
  color: #333;
  padding: 70px;
  margin-bottom: 50px;
}


.about > h1 {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.about__info {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.pic__container {
  margin-right: 50px;
}
.about__pic {
  border-radius: 999px;
  overflow: hidden;
  height: 250px;
}

.about_stuff {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
}

.about__stuff > h1 {
  font-size: 30px;
  text-align: left;
  margin-bottom: 5px;
}

.about__stuff > h3 {
  font-size: 20px;
  line-height: 30px;
  color: #777;
  padding-bottom: 20px;
}

@media (max-width: 760px) {
  .about {
    margin: 0px;
  }
}

@media (max-width: 1080px) {
  .about__info {
    display: flex;
    flex-direction: column;
  }

  .about {
    margin-bottom: 50px;
  }
}
